import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '@/shared/utils';

export const getTaskTypes = async () => {
	try {
		const {
			data: { GetTaskTypes }
		} = await apollo.query({
			query: gql`
				query GetTaskTypes {
					GetTaskTypes {
						id
						name
						picture
						value
						duration
						isSystem
						priority
						dayDuration
						totalItem
						description
					}
				}
			`,
			fetchPolicy: 'no-cache'
		});

		return GetTaskTypes;
	} catch (e) {
		console.log(e.message);
	}
};

export const getTaskType = async (taskTypeId) => {
	try {
		const {
			data: { GetTaskType }
		} = await apollo.query({
			query: gql`
				query ($TaskTypeId: ID!) {
					GetTaskType(TaskTypeId: $TaskTypeId) {
						id
						name
						picture
						value
						duration
						isSystem
						priority
						dayDuration
						totalItem
						description
					}
				}
			`,
			variables: {
				TaskTypeId: parseInt(taskTypeId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetTaskType;
	} catch (e) {
		console.log({ e });
	}
};

export const addUpdateTaskType = async (taskTypeId, updatedTaskType) => {
	try {
		const response = await apollo.mutate({
			mutation: gql`
				mutation AddUpdTaskType($taskTypeId: ID!, $updatedTaskType: TaskTypeInput!) {
					AddUpdTaskType(TaskTypeId: $taskTypeId, UpdatedTaskType: $updatedTaskType) {
						id
						name
						picture
						value
						duration
						isSystem
						priority
						dayDuration
						description
					}
				}
			`,
			variables: {
				taskTypeId: parseInt(taskTypeId, 10),
				updatedTaskType
			},
			fetchPolicy: 'no-cache'
		});

		const { AddUpdTaskType } = response.data;

		return AddUpdTaskType;
	} catch (e) {
		console.log(e.message);
	}
};

export const deleteTaskType = async (taskTypeId) => {
	try {
		const response = await apollo.mutate({
			mutation: gql`
				mutation Mutation($taskTypeId: ID!) {
					DelTaskType(TaskTypeId: $taskTypeId)
				}
			`,
			variables: {
				taskTypeId: parseInt(taskTypeId, 10)
			},
			fetchPolicy: 'no-cache'
		});
		const { DelTaskType } = response.data;
		return DelTaskType;
	} catch (e) {
		console.log(e.message);
	}

	return 'Cannot remove, task type in use';
};

export const updatedTaskTypeItem = async (taskTypeItemId, updTaskTypeItem) => {
	try {
		const mutation = gql`
			mutation ($TaskTypeItemId: ID!, $UpdTaskTypeItem: TaskTypeItemInput!) {
				AddUpdTaskTypeItem(TaskTypeItemId: $TaskTypeItemId, UpdTaskTypeItem: $UpdTaskTypeItem) {
					id
					taskTypeId
					name
					unit
					customUnit
					quantity
					unitPrice
					totalPrice
				}
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				TaskTypeItemId: parseInt(taskTypeItemId, 10),
				UpdTaskTypeItem: updTaskTypeItem
			}
		});

		return data.AddUpdTaskTypeItem;
	} catch (e) {
		console.error({ e });
	}
};

/**
 * @param {String|Number} taskTypeItemId
 */
export const delTaskTypeItem = async (taskTypeItemId) => {
	try {
		const mutation = gql`
			mutation ($TaskTypeItemId: ID!) {
				DelTaskTypeItem(TaskTypeItemId: $TaskTypeItemId)
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				TaskTypeItemId: parseInt(taskTypeItemId, 10)
			}
		});

		return data.DelTaskTypeItem;
	} catch (e) {
		console.error({ e });
	}
};

/**
 * get all tasks
 * @param {String, Number} taskId
 */
export const getTaskTypeItems = async (taskTypeId) => {
	// console.log("tasktypeid:",taskTypeId)
	if (isNil(taskTypeId)) {
		taskTypeId = 0;
		// console.log("tasktypeid:",taskTypeId)
	}
	try {
		const {
			data: { GetTaskTypeItems }
		} = await apollo.query({
			query: gql`
				query ($TaskTypeId: ID!) {
					GetTaskTypeItems(TaskTypeId: $TaskTypeId) {
						id
						createdAt
						taskTypeId
						name
						unit
						customUnit
						quantity
						unitPrice
						totalPrice
					}
				}
			`,
			variables: {
				TaskTypeId: parseInt(taskTypeId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetTaskTypeItems;
	} catch (e) {
		console.log({ e });
	}
};

const mutationResTask = `{
  id
}`;
